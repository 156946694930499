* {
  overflow-x: hidden;
  margin: 0;
}
.body{
  overflow-x: hidden;
}
.App-header {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(230, 230, 230);
}

.App-logo {
  overflow-y: hidden;
  width: 10em;
  height: 9em;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.App-logo:hover {
  overflow-y: hidden;
  transform: scale(0.9);
}

.MenuDesktop {
  overflow-y: hidden;
  display: none;
}

.imgMenu {
  overflow-y: hidden;
  width: 25px;
  cursor: pointer;
}

.Menu-options {
  overflow-y: hidden;
  position: absolute;
  width: 100vw;
  margin-top: -2.4em;
  text-align: start;
  font-size: 16px;
  font-weight: bold;
  background-color: rgb(0, 170, 255);
}

.Menu-options ul {
  overflow-y: hidden;
  margin: 0;
  padding: 0;
}

.Menu-options-li {
  overflow-y: hidden;
  padding: 10px;
  text-decoration: none;
  border: solid 1px white;
  z-index: 99;
}

.Menu-options a {
  overflow-y: hidden;
  margin-left: 1em;
  text-decoration: none;
  color: white;
}

/* Primeira Section */
.home {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.titulo {
  overflow-y: hidden;
  width: 70%;
  color: rgb(0, 0, 150);
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.text-home {
  overflow-y: hidden;
  width: 70%;
  margin-top: 2em;
  text-align: justify;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 16px;
}

.btnContratar {

  width: 50%;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  margin-top: 1.5em;
  color: white;
  background-color: rgb(0, 170, 255);
  transition: transform 0.3s ease;
  overflow-y: hidden;
}

.btnContratar:hover {
  overflow-y: hidden;
  background-color: rgb(0, 81, 122);
  transform: scale(0.8);
}

/* como funciona css */
.ComoFunciona {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.ComoFunciona h5 {
  overflow-y: hidden;
  margin-bottom: 1em;
  color: rgb(0, 0, 165);
}

.ComoFunciona h2 {
  overflow-y: hidden;
  margin-bottom: 1em;
  color: rgb(0, 0, 135);
}

.ComoFunciona h3 {
  overflow-y: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  width: 90%;
  margin-top: 0.5em;
  border-radius: 8px;
  padding: 10px;
  color: rgb(0, 0, 135);
  background-color: rgba(255, 255, 255, 0.502);
}

.descricao {
  overflow-y: hidden;
  width: 70%;
  margin: 1em;
  color: rgb(0, 0, 135);
  text-align: justify;
}

.negrito {
  overflow-y: hidden;
  font-weight: bold;
}

.textFunciona {
  overflow-y: hidden;
  margin-bottom: 1.5em;
}

.ComoFunciona span {
  overflow-y: hidden;
  line-height: 1.5;
}

/* consultas */
.Consultas {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 2em;
}

.Consultas h1 {
  overflow-y: hidden;
  width: 100%;
  font-weight: 1000;
  font-size: 40px;
  letter-spacing: -4.6px;
  color: rgb(0, 0, 135);
  opacity: 0.1;
}

.Consultas h5 {
  overflow-y: hidden;
  margin-top: -0.7em;
  color: rgb(0, 0, 165);
}

.desconto {
  overflow-y: hidden;
  color: rgb(0, 210, 0);
}

.Consultas h2 {
  overflow-y: hidden;
  width: 85%;
  margin-top: 1em;
  margin-bottom: 2em;
  color: rgb(0, 0, 135);
}

/* card consulta */
.card-desktop {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* padrao de cards */
.card {
  overflow-y: hidden;
  position: relative;
  width: 90%;
  height: 90vh;
  border: solid 1px rgb(215, 215, 215);
  border-radius: 8px;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: box-shadow 0.3s ease;
}

.card::after {
  overflow-y: hidden;
  content: "48%OFF";
  width: 200px;
  position: absolute;
  top: 20px;
  right: -70px;
  transform: rotate(45deg);
  background-color: rgb(0, 170, 255);
  font-weight: 500;
  color: white;
  padding: 5px;
  border-radius: 3px;
}

.card:hover {
  overflow-y: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.301);
}

/* card2 */
.card-celular::after {
  overflow-y: hidden;
  content: "35%OFF";
  width: 200px;
  position: absolute;
  top: 20px;
  right: -70px;
  transform: rotate(45deg);
  background-color: rgb(0, 170, 255);
  font-weight: 500;
  color: white;
  padding: 5px;
  border-radius: 3px;
}

/* card3 */
.card-premium {
  overflow-y: hidden;
  height: 100vh;
}

.card-premium::after {
  overflow-y: hidden;
  content: "43%OFF";
  width: 200px;
  position: absolute;
  top: 20px;
  right: -70px;
  transform: rotate(45deg);
  background-color: rgb(0, 170, 255);
  font-weight: 500;
  color: white;
  padding: 5px;
  border-radius: 3px;
}

.titulo {
  overflow-y: hidden;
  padding-top: 1em;
  font-weight: 800;
}

.descricao-premium {
  overflow-y: hidden;
  width: 90%;
  height: 30%;
  margin-top: -1em;
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 0, 135);
}

.descricao-card {
  overflow-y: hidden;
  width: 90%;
  font-size: 14px;
  font-weight: 500;
  color: rgb(0, 0, 135);
}

.card p {
  overflow-y: hidden;
  text-decoration: line-through;
}

.price {
  overflow-y: hidden;
  width: 100%;
  height: 14vh;
  position: relative;
  display: inline-block;
  font-weight: 800;
  color: rgb(0, 0, 135);
}

.currency,
.small-number {
  overflow-y: hidden;
  position: absolute;
  font-size: 18px;
  font-weight: 800;
  top: 10px;
}

.currency {
  overflow-y: hidden;
  left: 90px;
}

.currency-celular {
  overflow-y: hidden;
  left: 70px;
}

.large-number {
  overflow-y: hidden;
  font-size: 62px;
  margin-left: 5px;
}

.info-list {
  overflow-y: hidden;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* margin-top: -2em; */
  margin-right: 1.5em;
  font-size: 14px;
  font-weight: 500;
  list-style-type: none;
  padding: 0;
}

.info-list-premium {
  margin-top: -8em;
  overflow-y: hidden;
  margin-bottom: 0.5em;
}

.info-list li {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.info-list img {
  overflow-y: hidden;
  padding-right: 1em;
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.btnContrate {
  overflow-y: hidden;
  width: 45%;
  font-weight: bold;
  text-decoration: none;
  padding: 12px;
  margin-top: 1em;
  border: none;
  border-radius: 25px;
  color: white;
  background-color: rgb(0, 201, 0);
  transition: background-color 0.3s ease;
}

.btnContrate:hover {
  overflow-y: hidden;
  background-color: rgb(0, 0, 135);
}

.btnContrate-premium {
  overflow-y: hidden;
  width: 45%;
  font-weight: bold;
  text-decoration: none;
  padding: 12px;
  margin-top: 1em;
  border: none;
  border-radius: 25px;
  color: white;
  background-color: rgb(0, 201, 0);
  transition: background-color 0.3s ease;
}
.btnContrate-premium:hover {
  overflow-y: hidden;
  background-color: rgb(0, 0, 135);
}

.btnContrate-celular {
  overflow-y: hidden;
  width: 45%;
  font-weight: bold;
  text-decoration: none;
  padding: 12px;
  margin-top: 1em;
  border: none;
  border-radius: 25px;
  color: white;
  background-color: rgb(0, 201, 0);
  transition: background-color 0.3s ease;
}
.btnContrate-celular:hover {
  overflow-y: hidden;
  background-color: rgb(0, 0, 135);
}

.textbtn {
  overflow-y: hidden;
  font-size: 12px;
  font-weight: 500;
}

/* Vantagens */
.vantagens {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.titulo-vantagens {
  overflow-y: hidden;
  width: 100%;
  font-weight: 1000;
  font-size: 40px;
  letter-spacing: -4.6px;
  color: rgb(0, 0, 135);
  opacity: 0.1;
}

.vantagens h5 {
  overflow-y: hidden;
  margin-top: -0.7em;
  color: rgb(0, 0, 165);
}

.sub-titulo {
  overflow-y: hidden;
  color: rgb(0, 0, 135);
}

.text-vantagens {
  overflow-y: hidden;
  width: 90%;
  margin-top: 1em;
  text-align: justify;
  font-size: 16px;
  line-height: 1.5;
}

.CardsV-desktop {
  overflow-y: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-info {
  overflow-y: hidden;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px rgb(210, 210, 210);
  border-radius: 16px;
  margin-top: 1em;
  background-color: rgba(0, 0, 0, 0.103);
}

.card-info img {
  overflow-y: hidden;
  width: 50px;
  margin-top: 1.5em;
  transition: width 0.3s ease;
  /* Transição suave de 0.3 segundos */
}

.card-info img:hover {
  overflow-y: hidden;
  width: 55px;
}

.card-info h3 {
  overflow-y: hidden;
  font-size: 15px;
  color: rgb(0, 0, 135);
  margin-bottom: 1em;
  margin-top: 1em;
}

.card-info span {
  overflow-y: hidden;
  width: 90%;
  font-size: 14px;
}

.card-info a {
  overflow-y: hidden;
  width: 50%;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  padding: 12px;
  border-radius: 20px;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: rgb(0, 170, 255);
  color: white;
  transition: background-color 0.3s ease, transform 0.3s ease;
  /* Transições suaves */
}

.card-info a:hover {
  overflow-y: hidden;
  background-color: rgb(0, 81, 122);
  transform: scale(1.1);
  /* Aumenta a escala em 10% */
}

/* Depoimentos */
.Depoimentos {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.titulo-depoimentos {
  overflow-y: hidden;
  width: 100%;
  font-weight: 1000;
  font-size: 40px;
  letter-spacing: -4.6px;
  color: rgb(0, 0, 135);
  opacity: 0.1;
}

.Depoimentos h5 {
  overflow-y: hidden;
  margin-top: -0.7em;
  color: rgb(0, 0, 165);
}

.CardsD-desktop {
  overflow-y: hidden;
  width: 90%;
  margin-top: 2em;
  background-color: rgba(0, 0, 0, 0.103);
  border: solid 1px rgb(210, 210, 210);
  border-radius: 16px;
}

.CardsD-desktop img {
  overflow-y: hidden;
  width: 80%;
  margin-top: 1em;
  transition: transform 0.3s ease;
}

.CardsD-desktop img:hover {
  overflow-y: hidden;
  transform: scale(0.9);
}

.card-dados {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 90%;
  margin-top: 1em;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.103);
}

.card-dados img {
  overflow-y: hidden;
  width: 50px;
  margin-top: 2em;
}

.card-dados h2,
h3 {
  overflow-y: hidden;
  color: rgb(0, 0, 165);
}

.card-dados span {
  overflow-y: hidden;
  width: 90%;
  text-align: justify;
  margin: 2em auto;
}

.card-dados ul {
  overflow-y: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: justify;
  margin-bottom: 1em;
}

.card-dados li {
  overflow-y: hidden;
  text-align: left;
  margin-top: 5px;
}

.progress-bar {
  overflow-y: hidden;
  display: flex;
  width: 85%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
}

.progress {
  overflow-y: hidden;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding-right: 0.5em;
  text-align: end;
  width: 85%;
  height: 100%;
  background-color: #007bff;
  border-radius: 10px;
}

.progress1 {
  overflow-y: hidden;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding-right: 0.5em;
  text-align: end;
  width: 70%;
  height: 100%;
  background-color: #007bff;
  border-radius: 10px;
}

.progress2 {
  overflow-y: hidden;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding-right: 0.5em;
  text-align: end;
  width: 90%;
  height: 100%;
  background-color: #007bff;
  border-radius: 10px;
}

.progress3 {
  overflow-y: hidden;
  font-size: 12px;
  font-weight: bold;
  color: white;
  padding-right: 0.5em;
  text-align: end;
  width: 95%;
  height: 100%;
  background-color: #007bff;
  border-radius: 10px;

}

.titulo-barstatus {
  overflow-y: hidden;
  width: 90%;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.space {
  overflow-y: hidden;
  margin-bottom: 2em;
}

/* FAQ */
.FAQ {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.titulo-FAQ {
  overflow-y: hidden;
  width: 100%;
  font-weight: 1000;
  font-size: 40px;
  letter-spacing: -4.6px;
  color: rgb(0, 0, 135);
  opacity: 0.1;
}

.FAQ h5 {
  overflow-y: hidden;
  margin-top: -0.7em;
  color: rgb(0, 0, 165);
}

.duvidas {
  overflow-y: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-bottom: 1em;
}

.duvidas h3 {
  overflow-y: hidden;
  width: 85%;
  color: white;
  margin-top: 1em;
  padding: 5%;
  background-color: #007bff;
}

.duvidas h4 {
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 90%;
  margin-top: 0.5em;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px;
  color: rgb(0, 0, 135);
  background-color: rgba(255, 255, 255, 0.502);
}

.listalgpd {
  overflow-y: hidden;
  width: 100%;
  margin-top: 1em;
  text-align: left;
  padding-left: 0;
}

.listalgpd li {
  overflow-y: hidden;
  margin-left: 0;
  padding-top: 0.5em;

}

.contrate {
  overflow-y: hidden;
  width: 100%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
  background-color: #007bff;
}

.contrate h5 {
  overflow-y: hidden;
  color: white;
  margin-top: 2em;
  margin-bottom: 1em;
}

.contrate span {
  overflow-y: hidden;
  width: 90%;
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.contrate a {
  overflow-y: hidden;
  width: 50%;
  color: white;
  text-decoration: none;
  border: solid 2px;
  border-radius: 25px;
  padding: 10px;
  margin-top: 2em;
  transition: transform 0.3s;
}

.contrate a:hover {
  overflow-y: hidden;
  transform: scale(1.1);
}

/* contato */
.contatos {
  overflow-y: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wpp {
  overflow-y: hidden;
  width: 52%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  border: solid 2px;
  border-radius: 20px;
  color: rgb(0, 200, 0);
  transition: transform 0.3s;
}

.wpp:hover {
  overflow-y: hidden;
  transform: scale(0.9);
}

.wppimg {
  overflow-y: hidden;
  width: 50px;
}

.logocontato {
  overflow-y: hidden;
  width: 200px;
}

.email {
  overflow-y: hidden;
  display: flex;
  margin-top: 1em;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.emailimg {
  overflow-y: hidden;
  width: 30px;
}

.contatos h3 {
  overflow-y: hidden;
  margin-top: 2em;
}

.contatos a {
  overflow-y: hidden;
  text-decoration: none;
  cursor: pointer;
}

.contatos span {
  overflow-y: hidden;
  text-align: center;
}

.pagamentoicon {
  overflow-y: hidden;
  width: 50%;
}

.footer p {
  overflow-y: hidden;
  padding: 10px;
  text-align: center;
  color: white;
  background-color: #007bff;
}

.mobilehome {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.desktopHome {
  overflow-y: hidden;
  display: none;
}

.gif3 {
  overflow-y: hidden;
  display: none;
}
.contatosD{
  display: none;
}
@media only screen and (max-width: 400px){
  .card-celular{
    height: 100vh;
  }
  .card-premium{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 130vh;
  }
  .info-list-premium{
    margin-top: -6em;
  }
  .card-desktop{
    height: 100%;
  }
}

/* desktop */
@media only screen and (min-width: 1024px) {
  .mobilehome {
    display: none;
  }

  .imgMenu,
  .Menu-options,
  .Menu {
    display: none;
  }

  .MenuDesktop {
    width: 100%;
    display: flex;
    text-align: center;
  }

  .App-header {
    overflow-y: hidden;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -1em;
    margin-bottom: 3em;
  }

  .App-logo {
    width: 15%;
    height: 20vh;
    margin-left: 15em;
  }

  .navbar {
    overflow-y: hidden;
    width: 100%;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;

  }

  .navbar li {
    overflow-y: hidden;
    display: flex;
    margin-left: 2em;
    text-decoration: none;
   
  }

  .navbar li a {
    color: #000;
    font-weight: bold;
    overflow-y: hidden;
    text-decoration: none;
    transition: color 0.3s;
}

.navbar li a:hover {
    color: #007bff; 
}


  .btnheader {
    text-decoration: none;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    width: 14%;
    padding: 10px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    border-radius: 16px;
    margin-left: 1em;
    background-color: #007bff;
    transition: transform 0.3s;
  }

  .btnheader:hover {
    transform: scale(1.1);
    background-color: #0050a5;
  }

/* Home */
  .desktopHome {
    width: 90vw;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

  }

  .home {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 8em;
  }

  .text-home {
    overflow-y: hidden;
    color: black;
    font-size: 16px;
    text-align: start;
  }

  .titulo {
    /* width: 50%; */
    margin-left: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 30px;
  }

  .titulo a {
    width: 20vw;
    font-size: 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 10px;
    margin-top: 1.5em;
    color: white;
    background-color: rgb(0, 170, 255);
    transition: transform 0.3s ease;
    overflow-y: hidden;
  }

  .gif1 {
    overflow-y: hidden;
    margin-left: 8em;
  }

  .gif3 {
    overflow-y: hidden;
    display: flex;
  }

  

  .ComoFunciona h3 {
    overflow-y: hidden;
    width: 50%;
    justify-content: space-between;

  }

  .textFunciona {
    overflow: hidden;
    font-weight: bold;
  }

  .descricao {
    overflow: hidden;
    width: 40%;
    text-align: center;

  }

  /* consulta */
  .card-desktop {
    overflow-y: hidden;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .priceD {
    margin-top: 2em;
  }

  .info-listD {
    margin-top: 0.01em;
  }

  .btnContrate-celular{
    margin-top: 9.5em;
  }
  .btnContrate{
    margin-top: 11.5em;
  }
  /* .btnD {
    margin-top: 12.2em;
  } */

  /* .btnD1 {
    margin-top: 14em;
  } */

  .card {
    overflow-y: hidden;
    width: 31%;
    margin-left: 15px;
    height: 125vh;
  }

  .card-celular {
    overflow-y: hidden;

  }

  /* vantagens */
  .text-vantagens {
    justify-self: center;
    align-self: center;
    text-align: center;
  }

  .CardsV-desktop {
    display: grid;
    grid-template-columns: 45% 45%;
    z-index: 99;
  }

  .card-info {
    justify-self: center;
    align-self: center;
    z-index: 99;
  }

  .card-info:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }

  .Depoimentos {
    margin-top: 4em;
  }

  .CardsD-desktop {
    width: 50%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
  }

  .CardsD-desktop img {
    justify-self: center;
    align-self: center;
  }

  .card-dados {
    width: 50%;
  }

  .card-dados span {
    width: 50%;
    text-align: center;
  }

  .progress-bar {
    width: 50%;
  }

  .titulo-barstatus {
    justify-content: flex-start;
    margin-left: 27em;
  }

  .card-dados ul {
    display: flex;
    margin-left: 50%;
  }

  .duvidas h3 {
    width: 50%;
    padding: 10px;
  }

  .duvidas h4 {
    width: 50%;
  }

  .pagamentoicon {
    width: 15%;

  }

  .wpp {
    width: 80%;
    height: 10%
  }

  .email {
    margin-bottom: 1em;
    margin-left: 1em;
  }

  .contatos {
    display: none;
  }

  .logocontato {
    width: 150px;
    z-index: 100;
  }

  .contatosD {
    margin-top: -2em;
    z-index: 100;
    display: grid;
    grid-template-columns: 30% 40%;
    align-items: center;
    justify-content: center;

  }

  .container {
    display: flex;

    align-items: center;
    justify-content: center;
  }

  .contato {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .textocontatosD {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 1em;
  }

  .textocontatosD a {
    text-decoration: none;

  }

  .textocontatosD h3 {
    margin-top: 1em;
    font-size: 22px;
  }

  .containerC {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .horario {
    text-align: center;
  }

  .pagamento {
    text-align: center;
  }

  .pagamentoicon {
    width: 70%;
  }

  .footer p {
    padding: 30px;
  }

  .titulo-vantagens,
  .titulo-depoimentos,
  .titulo-FAQ,
  .Consultas h1 {
    font-size: 64px;
  }
}
@media only screen and (min-width: 1750px){
  
  .btnContrate-premium{
    margin-top: 5em;
  }
  .btnContrate{
    margin-top: 18em;
  }
  .btnContrate-celular{
    margin-top: 16.5em;
  }
}